import React, { Component } from "react";
import "./DestinationStyles.css";
import Mountain1 from "../assets/frigga1.jpg";
import Mountain3 from "../assets/otherpagebg.jpg";

class DestinationData extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="des-text">
          <h2>{this.props.heading}</h2>
          <p>{this.props.text}</p>
        </div>
        <div className="image">
          <img src={this.props.img} alt="img" />
        </div>
      </div>
    );
  }
}

export default DestinationData;
