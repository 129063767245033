import Navbar from "../components/Navbar";
import HomeHero from "../components/HomeHero";
import AboutImg from "../assets/night.jpg";
import Footer from "../components/Footer";
import Trip from "../components/Trip";
import Background from "src/components/Background";

function Service() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <Navbar />
      {/* <Background /> */}
      {/* <HomeHero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Service"
        btnClass="hide"
      /> */}
      <Trip />
      <Footer />
    </>
  );
}

export default Service;
