import { useState, useEffect } from 'react'; // Import useState and useEffect hooks
import "../components/CareersDataStyles.css";
import careerspic1 from "../assets/Careers1.jpg";
import careerspic2 from "../assets/Careers2.jpg";

function CareersData() {
  const [isOpeningsAvailable, setIsOpeningsAvailable] = useState(false); // State to track if openings are available

  // Function to handle button click
  const handleButtonClick = () => {
    setIsOpeningsAvailable(true); // Set isOpeningsAvailable state to true
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the pop-up content
      const popupContent = document.querySelector('.popup-content');
      if (popupContent && !popupContent.contains(event.target)) {
        setIsOpeningsAvailable(false); // Close the pop-up
      }
    };

    // Add event listener when the pop-up opens
    if (isOpeningsAvailable) {
      document.body.addEventListener('click', handleClickOutside);
    }

    // Remove event listener when the component unmounts or when the pop-up closes
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isOpeningsAvailable]);

  return (
    <div className="about-container">
      
      <h2>Careers</h2>
      <p>Frigga Consulting Group provides equal employment opportunities to all employees and applicants for employment and prohibits discrimination and harassment of any type without regard to race, color, religion, age, sex, national origin, disability status, genetics, protected veteran status, sexual orientation, gender identity or expression, or any other characteristic protected by federal, state or local laws.</p>

      <br />
      <img src={careerspic1} alt="" />
      <p>
        This policy applies to all terms and conditions of employment, including recruiting, hiring, placement, promotion, termination, layoff, recall, transfer, leaves of absence, compensation and training.
      </p>

      <br />
      <h2>Opportunities with FCG</h2>
      
      <div className="button-container">
        <button className="apply-now-button" onClick={handleButtonClick}>Search Openings</button>
      </div>
      
      <br />
      <img src={careerspic2} alt="" />

      {/* Conditionally render pop-up if no openings available */}
      {isOpeningsAvailable && (
        <div className="popup">
          <div className="popup-content">
            <p>No available openings at the moment.</p>
            <button onClick={() => setIsOpeningsAvailable(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CareersData;
