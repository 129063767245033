import React, { useState, useEffect } from "react";
import image1 from "../assets/slideshow1.jpg";
import image2 from "../assets/slideshow2.jpg";
import image3 from "../assets/slideshow3.jpg";
import image4 from "../assets/slideshow4.jpg";
import image5 from "../assets/slideshow5.jpg";
import "../components/AboutUsStyles.css";

function AboutUs() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [image1, image2, image3, image4, image5];
  const [timer, setTimer] = useState(5); // Initial timer value

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setTimer(5); // Reset timer to 3 seconds for each slide change
    }, 5000);

    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1); // Decrement timer every second
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(countdown);
    };
  }, [currentImageIndex, images.length]);

  return (
    <div className="about-container">
      <h1 className="section-heading">Our History</h1>
      <div className="section">
        <p className="section-description">
          Our global landscape confronts continually changing security challenges, spanning transnational crime, illicit trafficking, terrorism, and insurgency. FCG takes pride in championing the crucial mission of the U.S. and its allies, dedicated to safeguarding citizens against these pressing threats. We achieve this objective by delivering:
        </p>
      </div>

      <div className="slideshow-container">
        <img src={images[currentImageIndex]} alt="Slideshow" />
        <div className="timer">{timer}</div>
      </div>

      <br />
      <br />

      <div className="section">
        <h2 className="subsection-heading">Specialized Expertise</h2>
        <div className="content-wrapper">
          <p>
            FCG brings together a team of experienced professionals with broad backgrounds in intelligence, law enforcement, and forensic financial examination. Our expertise enables us to address complex challenges in depth.
          </p>
        </div>
      </div>

      <div className="section">
        <h2 className="subsection-heading">Collaborative Approach</h2>
        <div className="content-wrapper">
          <p>
            We prioritize forging strong partnerships with our clients and stakeholders. By working collaboratively, we craft innovative, tailored strategies that produce measurable and impactful results.
          </p>
        </div>
      </div>

      <div className="section">
        <h2 className="subsection-heading">Ethical Standards</h2>
        <div className="content-wrapper">
          <p>
            Upholding the highest standards of professional conduct is our cornerstone. We conduct our operations with integrity, transparency, and a steadfast dedication to ethical practices. 
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
