import "./FooterStyles.css";
import { useState, useEffect } from "react";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to check if user has scrolled enough to display the "Back to Top" button
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const footerHeight = document.querySelector(".footer").clientHeight;

    if (scrollPosition + windowHeight >= document.body.scrollHeight - footerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Smooth scrolling behavior
    });
  };

  // Add event listener to scroll event
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Add an empty dependency array to run this effect only once

  return (
    <>
      <div className="footer">
        <div className="top">
          <div>
            <h2>Frigga Consulting Group</h2>
            <p>contact@friggacg.com</p>
            <br></br>
            <p>DUNS: 118853578</p>
            <p>UEI: P7HSRBV492F2</p>
            <br></br>
          </div>
          <div>
            <a href="https://www.facebook.com/profile.php?id=61556605140756">
              <i className="fa-brands fa-facebook-square"></i>
            </a>
            <a href="">
              <i className="fa-brands fa-instagram-square"></i>
            </a>
            <a href="https://www.linkedin.com/company/friggacg.com/">
              <i className="fa-brands fa-linkedin"></i> 
            </a>
          </div>
        </div>
        <button className={`back-to-top ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
          Back to Top
        </button>
      </div>
    </>
  );
};

export default Footer;
