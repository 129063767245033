import React from "react";
import Mountain1 from "../assets/Homepage1.jpg";
import Mountain2 from "../assets/otherpagebg.jpg";
import Mountain3 from "../assets/otherpagebg.jpg";
import Mountain4 from "../assets/frigga4.jpg";
import DestinationData from "./DestinationData";

const Destination = () => {
  return (
    <>
      <div className="destination">
        {/* <h1>A Little About Us</h1> */}
        <p>Frigga Consulting Group (FCG) is a woman owned global mission support service provider
          with expeditionary capabilities, providing services to select clients within the U.S. intelligence,
          defense, and federal law enforcement communities, as well as private sector partners. With an
          unwavering commitment to excellence and over 35 combined years’ experience, FCG stands as a
          beacon of integrity, innovation, and a provider of impactful solutions supporting national
          security.</p>
        <DestinationData
          className="first-des"
          heading="Our Mission"
          text="Safeguarding the integrity of financial systems and bolster global security by
          delivering unparalleled expertise and strategic insights in support of senior government
          executives, corporate decisionmakers, and warfighters. We empower governments, law
          enforcement agencies, and organizations worldwide with tailored solutions that mitigate risks,
          combat financial crimes, and enhance operational effectiveness."
          img={Mountain1}
        />

        <DestinationData
          className="first-des-reverse"
          heading="Our Team"
          text="Of prior federal law enforcement, security, and intelligence officers with military
          backgrounds possesses extensive expertise in financial crime investigations, support to
          operational and strategic security operations, and a deep understanding of U.S. and international
          economic and trade sanctions programs. We excel in financial intelligence, law enforcement
          financial investigations, and Department of Defense counter threat finance analysis, and support
          to efforts denying America’s adversaries access to the U.S. and global financial systems."
          img={Mountain2}
        />
      </div>
    </>
  );
};

export default Destination;

// function Destination() {
//   return (
//     <>
//       <div className="destination">
//         <h1>Popular Destinations</h1>
//         <p>Tours give you the opportunity to see a lot, within a time frame.</p>
//         <div className="first-des">
//           <div className="des-text">
//             <h2>Taal Volcano, Batangas</h2>
//             <p>
//               One of the most iconic views in Luzon, Mt. Taal boasts a volcano
//               inside a lake inside an island. If you fancy a closer look, the
//               hike up to the crater is a mere 45 minutes, and is easy enough for
//               beginners. Guides will assist you most of the way, and you’ll see
//               the peculiar environment found on an active volcano, including
//               volcanic rocks and steam vents. The hike can be dusty and hot, so
//               plan for an early morning trip, and then unwind with some bulalo
//               before heading back home!
//             </p>
//           </div>
//           <div className="image">
//             <img src={Mountain1} alt="img" />
//             <img src={Mountain2} alt="img" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Destination;
