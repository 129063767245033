export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-house-user"
  },
  {
    title: "About Us",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  {
    title: "Expertise",
    url: "/expertise",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase"
  },
  {
    title: "Clients",
    url: "/service",
    cName: "nav-links",
    icon: "fa-solid fa-address-book"
  },
  {
    title: "Careers",
    url: "/careers",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase"
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  // {
  //   title: "Contact!",
  //   url: "/contact",
  //   cName: "nav-links-mobile"
  // },
  // {
  //   title: "Contact!",
  //   url: "/contact",
  //   cName: "nav-links-mobile"
  // }
];
