import "../components/HomeHeroStyles.css";
import friggalogo from "../assets/homepagelogo.jpg";
import friggabg from "../assets/stockvideo2compressed.mp4";


function HomeHero(props) {
  return (
    <>
      <div className={props.cName}>
        {/* <video  loop={true} muted={true} autoplay={true} playsinline={true} src={props.heroImg} alt="heroImg" /> */}
        
        {/* <video controls autoPlay loop muted>
            <source src={props.heroImg} type="video/mp4"/>
        </video> */}
        
        <video autoPlay loop playsInline muted>
            <source src={friggabg} type="video/mp4"/>
        </video>

        {/* <img src={props.heroImg} alt="heroImg" /> */}
        <div className="hero-text">

        <img className="frigga-logo" src={friggalogo} />

          <h1>{props.title}</h1>
          <p>{props.text}</p>
          <a className={props.btnClass} href={props.url}>
            {props.buttonText}
          </a>


        </div>
        {/* <h1>Your Journey Your Story</h1>
        <p>Choose Your Favourite Destination.</p>
        <a href="index.html">Travel Plan</a> */}
      </div>
    </>
  );
}

export default HomeHero;
