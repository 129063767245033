import "../components/ContactFormStyles.css";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Contact() {
  // Function to load Google Maps API asynchronously
  const loadGoogleMapScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDJdBvRVbZQODiz8EGRBsLsRIcXDruLTQk&callback=initMap`;
    script.defer = true;
    document.head.appendChild(script);
  };

  // Function to initialize the map
  window.initMap = () => {
    // Initialize the map
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 38.9071, lng: -77.0373 },
      zoom: 12,
    });

    // Add marker to the map
    const marker = new window.google.maps.Marker({
      position: { lat: 38.9071, lng: -77.0373 },
      map: map,
      title: "Our Location",
    });
  };

  // Load Google Maps API when the component mounts
  React.useEffect(() => {
    loadGoogleMapScript();
  }, []);

  return (
    <div className="contact-page">
      <Navbar />
      <div className="contact-container">
        <h1 className="contact-heading"></h1>
        <div className="contact-info">
          <h2 className="info-heading">Frigga Consulting Group</h2>
          <p>8411 Patterson Ave</p>
          <p>Henrico, VA 23229</p>
          {/* <p>Phone: (123) 456-7890</p> */}
          <p>Email: contact@friggacg.com</p>
        </div>
      </div>
      <div id="map" className="map-container"></div>
      {/* <div className="business-hours">
        <h2 className="hours-heading">Business Hours</h2>
        <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
        <p>Saturday: 10:00 AM - 2:00 PM</p>
        <p>Sunday: Closed</p>
      </div> */}
      <Footer />
    </div>
  );
}

export default Contact;
