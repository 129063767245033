import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import "./NavbarStyles.css";
import frigganavbar from "src/assets/homepagelogofinal.jpg";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav className="NavbarItems">
        {/* <h1 className="navbar-logo">Frigga Consultant Group</h1> */}

        <img src={frigganavbar} className="navbar-logo" alt="Frigga Consultant Group"/>

        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
              
            );
          })}
          {/* <button>Contact</button> */}
        </ul>

      </nav>
    );
  }
}

export default Navbar;
