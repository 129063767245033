import "../components/ExpertiseStyles.css";
import { useEffect, useRef } from 'react';

function ExpertiseData() {
    const fadeInRef = useRef(null);

    useEffect(() => {
        const fadeInElements = fadeInRef.current.querySelectorAll('.fade-in');

        function fadeInText() {
            fadeInElements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementTop < windowHeight) {
                    element.classList.add('fade-in-animation');
                }
            });
        }

        window.addEventListener('scroll', fadeInText);
        fadeInText(); // Call the function once to start fading in immediately
        return () => window.removeEventListener('scroll', fadeInText);
    }, []);

    return (
        <div className="about-container" ref={fadeInRef}>
            <div className="expertise-banner">
                <h1 className="fade-in">Our Expertise</h1>
            </div>
            <div className="expertise-section">
                <h3 className="fade-in">Law Enforcement Operational and Investigation Support</h3>
                <p className="fade-in">FCG excels in facilitating collaboration between law enforcement agencies, promoting information sharing, and supporting investigations to combat criminal organizations and security threats.</p>
                <p className="fade-in">Forensic Financial Examination and Fraud Investigation: Our forensic financial experts analyze financial data to uncover patterns and provide crucial insights for legal proceedings and enforcement actions.</p>
                <p className="fade-in">Economic Sanctions and Foreign Investment (CFIUS) Consulting: We offer specialized guidance on economic sanctions, helping clients navigate complexities and develop informed, legally sound decisions.</p>
                <p className="fade-in">Open-Source Intelligence, KYC, and Due Diligence Services: Utilizing multiple languages, including Arabic, Russian, Spanish, Pashto, and Dari, we conduct thorough due diligence to reveal hidden relationships and connections.</p>
                <p className="fade-in">Cryptocurrency Investigation and Chain Analysis: Our team uses advanced tools to trace and analyze cryptocurrency transactions, aiding in the fight against money laundering, fraud, and cybercrime.</p>
            </div>

            <div className="expertise-section">
                <h3 className="fade-in">Operations & Administrative Support</h3>
                <p className="fade-in">With extensive experience and specialized education in global management, our team enables supported customers to succeed in their mission by providing essential services, including:</p>
                <p className="fade-in">Market Research: Conducting in-depth analysis to identify potential markets.</p>
                <p className="fade-in">Networking and Outreach: Building valuable connections to enhance opportunities.</p>
                <p className="fade-in">Business Capture and Proposal Support: Crafting compelling and detailed proposals.</p>
                <p className="fade-in">Compliance Checks: Ensuring all proposals meet regulatory requirements.</p>
                <p className="fade-in">Budgeting and Cost Analysis: Providing financial insights for informed decision-making.</p>
                <p className="fade-in">Risk Assessment: Identifying and mitigating potential risks.</p>
                <p className="fade-in">Relationship Management: Building and maintaining strong business relationships.</p>
                <p className="fade-in">Client Consultation: Offering expert advice tailored to client needs.</p>
                <p className="fade-in">Partnership Development: Facilitating strategic partnerships.</p>
            </div>

            <div className="expertise-section">
                <h3 className="fade-in">Client Support and Communication</h3>
                <p className="fade-in">Dedicated Support Team: Addressing client queries and concerns with consistent updates and reports on project progress.</p>
                <p className="fade-in">Feedback Mechanism and Post-Project Evaluation: Establishing a system for client feedback and conducting thorough evaluations to assess project outcomes and identify areas for improvement.</p>
            </div>

            <div className="expertise-section">
                <h3 className="fade-in">Post-Capture Contract and Program Management Support and Contract Implementation</h3>
                <p className="fade-in">Project Management: Overseeing projects to ensure timely and successful completion.</p>
                <p className="fade-in">Resource Allocation: Efficiently distributing resources to maximize project efficiency.</p>
                <p className="fade-in">Quality Assurance: Maintaining high standards throughout the project lifecycle.</p>
            </div>

            <div className="expertise-section">
                <h3 className="fade-in">Financial Consultation</h3>
                <p className="fade-in">Invoicing and Payment Tracking: Managing billing and payments efficiently.</p>
                <p className="fade-in">Financial Reporting: Providing clear and comprehensive financial reports.</p>
                <p className="fade-in">Regulatory Compliance: Ensuring all activities comply with regulatory standards and providing thorough reporting.</p>
                <p className="fade-in">Fraud, Waste, and Abuse Identification and Investigation</p>
            </div>
        </div>
    );
}

export default ExpertiseData;
