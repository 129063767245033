import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/client1.jpg";
import Trip2 from "../assets/client2.jpg";
import Trip3 from "../assets/client3.jpg";
import Trip4 from "../assets/departmentofjustice.jpg"

function Trip() {
  return (
    <div className="trip">
      
      {/* <h1>Clients</h1> */}
      {/* <p>Our Partners</p> */}
      <div className="tripcard">
        <TripData
          image={Trip1}
          heading=""
          text=""
        />
        <TripData
          image={Trip2}
          heading=""
          text=""
        />
        <TripData
          image={Trip3}
          heading=""
          text=""
        />
        <TripData
          image={Trip4}
          heading=""
          text=""
        />
      </div>
    </div>
  );
}

export default Trip;
